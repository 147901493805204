<template>
  <div class="contact-us-page">
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" sm="12" xs="12">
        <div class="text-center">
          <vuetify-logo />
        </div>
        <h2> حسابات سمو النخيل للتمور</h2>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon orange">
            <i class="fa fa-home"></i>
          </div>
          <router-link to="/">الدخول للمتجر الإلكتروني</router-link>
          <!--<a :href="social.smoo" target="_blank">العودة للمتجر الإلكتروني</a>-->
        </div>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon green">
            <i class="fab fa-whatsapp"></i>
          </div>
          <a
            :href="
              'https://api.whatsapp.com/send?phone=' + contactData.whatsapp
            "
            target="_blank"
            >واتس اب المتجر</a
          >
        </div>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon black">
            <i class="fa-brands fa-tiktok"></i>
          </div>
          <a :href="social.tiktok" target="_blank">تيك توك</a>
        </div>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon yellow">
            <i class="fa fa-snapchat-ghost"></i>
          </div>
          <a :href="social.snapchat" target="_blank">سناب شات</a>
        </div>
        <div md="6" sm="6" xs="6" class="contact-item">
          <div class="icon purple">
            <i class="fab fa-instagram"></i>
          </div>
          <a :href="social.instagram" target="_blank">إنستقرام</a>
        </div>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon baby-blue">
            <i class="fab fa-twitter"></i>
          </div>
          <a :href="social.twitter" target="_blank">تويتر</a>
        </div>
        <!--<div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon blue">
            <i class="far fa-envelope"></i>
          </div>
          <a :href="'mailto:' + contactData.contact_email" target="_blank">{{
            contactData.contact_email
          }}</a>
        </div>-->
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon red">
            <i class="fa fa-map-marker"></i>
          </div>
          <a :href="social.branch" target="_blank">فروعنا - بالرياض</a>
        </div>
        <div  md="6" sm="6" xs="6" class="contact-item">
          <div class="icon grown">
            <i class="fa fa-location"></i>
          </div>
          <a :href="social.masna" target="_blank"> مصنعنا لطلب تصنيع وتعبئة</a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ServiceFactory } from "../services/ServiceFactory";
const aboutService = ServiceFactory.get("about");

import VuetifyLogo from "../components/VuetifyLogo.vue";
export default {
  data: () => ({
    // social: {},
    social: {
      snapchat: "",
      twitter: "",
      instagram: "",
      tiktok:"https://www.tiktok.com/@smooalnakhel",
      smoo:"/",
      branch:"/branch",
      masna:"/masna"
    },
    // contactData: null,
    contactData: {
      contact_phone: "",
      contact_email: "",
      whatsapp: "",
    },
  }),
  components: {
    VuetifyLogo,
  },
  created() {
    this.fetchAllItems();
    //, this.fetchAllContact();
    // if (this.$route) {
    // }

    console.log(this.$route.name);
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await aboutService.getSocialData();
      this.contactData.contact_phone = data.contact_phone;
      this.contactData.contact_email = data.contact_email;
      this.contactData.whatsapp = data.whatsapp;
      this.social.snapchat = data.snapchat;
      this.social.twitter = data.twitter;
      this.social.instagram = data.instagram;
      this.dataLoading = false;
    },
    // async fetchAllContact() {
    //   this.dataLoading = true;
    //   const contactData = await aboutService.getContactData();
    //   this.contactData = contactData.contactus;
    //   console.log(this.contactData);
    //   this.dataLoading = false;
    // },
  },
};
</script>

<style>
.contact-us-page {
  font-family:Arial, Helvetica, sans-serif !important;
}
.contact-us-page h2 {
  margin: 15px 0 18px 0;
  text-align: center;
  color: #614c37 !important;
  font-size: 17.5px !important;
}
.contact-us-page .contact-item {
  width:89%;
  display:flex;
  direction: rtl;
  align-items: center !important;
  margin: auto;
  margin-bottom: 15px;
}
.contact-us-page .contact-item a {
  text-align: center !important;
  padding: 6px 0px !important;
}
.contact-us-page .contact-item .icon {
  color: #fff;
  width: 65px;
  height: 65px;
  font-size: 30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  /*flex: 0 0 80px;*/
}
.contact-us-page .contact-item .green {
  background-color: #20d268 !important;
}
.contact-us-page .contact-item .baby-blue {
  background-color: #1da1f2;
}
.contact-us-page .contact-item .blue {
  background-color: #3f51b5 !important;
}
.contact-us-page .contact-item .yellow {
  background-color: #fbf706 !important;
}
.contact-us-page .contact-item .purple {
  background-color: #c13584 !important;
}
.contact-us-page .contact-item .grown {
  background-color: #c15a35 !important;
}
.contact-us-page .contact-item a {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background-color: #fff;
  direction: ltr;
  width: 85%;
  text-align: right;
  padding: 9px;
  border-radius: 12px 0 0 12px;
  text-decoration: none;
  color: #000;
  font-weight: normal;
  font-size: 15px;
}
@media only screen and (max-width: 376px) {
  .contact-us-page .contact-item {
  width:100% !important;
}
} 
  
</style>
